// Add element-ui locale
import elementLocaleDE from 'element-ui/lib/locale/lang/de';
import elementLocaleEN from 'element-ui/lib/locale/lang/en';
import elementLocaleDA from 'element-ui/lib/locale/lang/da';
import { i18n } from '../plugins/vue-i18n';

/* eslint no-underscore-dangle: ["error", { "allow": ["_vm"] }] */
export default {
  /**
   * TEST TIMEOUT
   */
  async testTimeout({ commit }) {
    const response = await this._vm.$http({
      method: 'get',
      url: `${localStorage.getItem('edeka_terminal_api_url')}/testTimeouttt`,
      // url: `${localStorage.getItem('edeka_terminal_api_url')}/op/user`,
      // params: {
      //   timeoutSeconds: 128,
      // },
      headers: {
        'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
      },
      timeout: 2000,
    });
    try {
      commit('SET_TEST', response.data);
      return response.data;
    } catch (e) {
      if (e.code === 'ECONNABORTED') {
        return 'timeout';
      }
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * ERROR LOG
   */
  async setError({ commit }, payload) {
    const formData = new FormData();
    formData.append('appType', payload.appType);
    formData.append('deviceType', payload.deviceType);
    formData.append('data', JSON.stringify(payload.jsonData));

    const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/log/error`, formData, {
      headers: {
        'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
      },
    });
    try {
      commit('SET_ERROR', response.data);
      return response.data;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * SETTINGS
   */
  async setSettings({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/settings`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
        },
      });
      commit('SET_SETTINGS', response.data);
      return true;
    } catch (e) {
      // localStorage.removeItem('auth');
      return e.status;
    }
  },

  /**
   * AUTH
   */
  setAuth({ commit }) {
    commit('SET_AUTH');
  },

  /**
   * USER
   */
  setUser({ commit }) {
    commit('SET_USER');
  },

  /**
   * PIN
   */
  async setPin({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/user/pin`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PIN', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * BOX TOKEN
   */
  async setBox({ commit }, { payload }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/boxByToken`, {
        params: {
          token: payload.token,
        },
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
        },
      });

      commit('SET_BOX', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * CART
   */
  async setCart({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/cart`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_CART', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async addItem({ commit }, { payload }) {
    const formData = new FormData();
    formData.append('articleEAN', payload.ean);
    formData.append('quantity', payload.quantity);
    if (payload.decision) {
      formData.append('decision', payload.decision);
    }

    try {
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/add`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('ADD_ITEM', response.data);
      return true;
    } catch (e) {
      return e.response;
    }
  },

  async addItemID({ commit }, { payload }) {
    const formData = new FormData();
    formData.append('articleID', payload.id);
    formData.append('quantity', payload.quantity);

    try {
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/add`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('ADD_ITEM', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async updateItem({ commit }, data) {
    if (data) {
      const formData = new FormData();
      formData.append('itemID', data.id);
      formData.append('quantity', data.quantity);
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/update`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      try {
        commit('UPDATE_ITEM', response.data);
        return true;
      } catch (e) {
        return e.status;
      }
    }
    return false;
  },

  async removeItem({ commit }, id) {
    const formData = new FormData();
    formData.append('itemID', id);

    try {
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/delete`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('REMOVE_ITEM', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async createOrder({ commit }, payload) {
    try {
      const response = await this._vm.$http({
        url: `${localStorage.getItem('edeka_terminal_api_url')}/cart/createOrder`,
        method: 'post',
        params: {
          paymentCategoryID: payload.paymentCategoryID,
          printInvoice: payload.printInvoice,
        },
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_ORDER', response.data);
      return response.data;
    } catch (e) {
      return e.status;
    }
  },

  // async createOrder({ commit }, payload) {
  //   this._vm.$http({
  //     url: `${localStorage.getItem('edeka_terminal_api_url')}/cart/createOrder`,
  //     method: 'post',
  //     params: {
  //       paymentCategoryID: payload.paymentCategoryID,
  //       printInvoice: payload.printInvoice,
  //     },
  //     headers: {
  //       'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
  //       Authorization: `Bearer ${localStorage.getItem('auth')}`,
  //     },
  //   })
  //     .then((response) => {
  //       commit('SET_ORDER', response.data);
  //       return response.data;
  //     })
  //     .catch((e) => {
  //       const st = e.status;
  //       return st;
  //     });
  // },

  async getOrders({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/orders`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_ORDER', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  async clearCart({ commit }) {
    const formData = new FormData();

    try {
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/cart/empty`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('CLEAR_CART', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * PAYMENT - CATEGORIES
   */
  async setPaymentCategories({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/cart/paymentCategories`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PAYMENT_CATEGORIES', response.data);
      return response.data;
    } catch (e) {
      return e.status;
    }
  },

  async getPayment({ commit }, payload) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/payment`, {
        params: {
          orderToken: payload.orderToken,
        },
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PAYMENT', response.data);
      return response.data;
    } catch (e) {
      return e.status;
    }
  },

  async setPaymentRetry({ commit }, payload) {
    const formData = new FormData();

    formData.append('orderToken', payload.orderToken);

    try {
      const response = await this._vm.$http.post(`${localStorage.getItem('edeka_terminal_api_url')}/payment/retry`, formData, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PAYMENT_RETRY', response.data);
      return response.data;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * PRODUCTS
   */
  async setProducts({ commit }) {
    try {
      const response = await this._vm.$http.get(`${localStorage.getItem('edeka_terminal_api_url')}/cart/getManualArticles`, {
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
          Authorization: `Bearer ${localStorage.getItem('auth')}`,
        },
      });

      commit('SET_PRODUCTS', response.data);
      return true;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * GLOBAL LOADING
   */
  setGlobalLoading({ commit }, status) {
    commit('SET_GLOBAL_LOADING', status);
  },

  /**
  * TRANSLATIONS
  */
  async setI18nData({ commit }, payload) {
    // this._vm.$http.defaults.timeout = 18000;
    // this._vm.$http.defaults.timeoutErrorMessage = 'timeout';

    try {
      const response = await this._vm.$http({
        method: 'get',
        url: `${localStorage.getItem('edeka_terminal_api_url')}/resources`,
        params: {
          locale: payload,
        },
        headers: {
          'X-Api-Key': `${localStorage.getItem('edeka_terminal_api_key')}`,
        },
      });

      // update language in localStorage + update store language
      const responseData = response.data;
      const translationsDE = {};
      const translationsEN = {};
      const translationsDA = {};
      let translationsFinal = {};

      responseData.forEach((row) => {
        // if german
        if (row.localeID === 22) {
          translationsDE[row.code] = row.text;
        }
        // if english
        if (row.localeID === 352) {
          translationsEN[row.code] = row.text;
        }
        if (row.localeID === 98) {
          // if english
          translationsDA[row.code] = row.text;
        }
      });
      // if german
      if (payload === 'de_AT') {
        translationsFinal = {
          ...translationsDE,
          ...elementLocaleDE,
        };
      }
      // if english
      if (payload === 'en_US') {
        translationsFinal = {
          ...translationsEN,
          ...elementLocaleEN,
        };
      }
      // if dänisch
      if (payload === 'da_DK') {
        translationsFinal = {
          ...translationsDA,
          ...elementLocaleDA,
        };
      }

      localStorage.removeItem(payload);
      localStorage.removeItem('boxLang');
      localStorage.setItem(payload, JSON.stringify(translationsFinal));
      localStorage.setItem('boxLang', payload);
      i18n.setLocaleMessage(localStorage.getItem('boxLang'), translationsFinal);
      commit('SET_I18N', response.data);
      return i18n;
    } catch (e) {
      return e.status;
    }
  },

  /**
   * PAGE TITLE
   */
  addPageTitle({ commit }, title) {
    commit('ADD_PAGE_TITLE', title);
  },

  removePageTitle({ commit }, title) {
    commit('REMOVE_PAGE_TITLE', title);
  },

  addPageBackUrl({ commit }, url) {
    commit('ADD_PAGE_BACK_URL', url);
  },

  removePageBackUrl({ commit }, url) {
    commit('REMOVE_PAGE_BACK_URL', url);
  },
};
